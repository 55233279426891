/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { CMSHeadline } from '@/features/contentstack/components/CMSHeadline/CMSHeadline';
import { useLabels } from '@/hooks/useLabels';
import { ArrowRight, Button, Hidden, View, Text, Icon, Link } from '@az/starc-ui';
import { routePaths } from '@/constants/routePaths';
import router from 'next/router';
import { useRewardsActivity } from '../../api/getRewardsActivity';
import { Activity } from '../../lib/components/Activity/Activity';
import styles from './RewardsActivity.module.scss';

const labelMap = {
  lblrewardsActivity: 'label_MyAccountMyProfile_orderhistory_RewardsActivity',
  lblseeAll: 'label_topnav_seeall',
};

export const RewardsActivity = () => {
  const labels = useLabels(labelMap);
  const { data: rewardsData } = useRewardsActivity();
  const rewardsActivityLineItems = (rewardsData?.rewardsActivityLineItems ?? []).slice(0, 4);

  const navigateToRewardsTransactions = () => {
    void router.push({
      pathname: routePaths.userOrderHistory,
      query: {
        tab: 'rewards',
      },
    });
  };

  return (
    <View>
      <View direction="row" align="center" justify="space-between">
        <CMSHeadline className={styles.headline} nested underline>
          {labels.lblrewardsActivity}
        </CMSHeadline>
        <Hidden hide={{ s: true, m: false }}>
          <Link
            onClick={navigateToRewardsTransactions}
            attributes={{ 'data-testid': 'see-all-button' }}
          >
            <Text size="087" weight="medium">
              {labels.lblseeAll}
            </Text>
          </Link>
        </Hidden>
      </View>
      <View
        direction="row"
        gap={{ s: 2, m: 6, l: 4 }}
        attributes={{ 'data-testid': 'activity-container' }}
      >
        {rewardsActivityLineItems.map((item, i) => (
          <View.Item
            columns={{ s: 12, l: 6 }}
            className={styles.activityContainer}
            key={`${item.transactionNumber}_${i}`}
            attributes={{ 'data-testid': `activity-item-${i}` }}
          >
            <Activity content={item} />
          </View.Item>
        ))}
      </View>
      <Hidden hide={{ s: false, m: true }}>
        <View direction="row" justify="start" className={styles.seeAllBtnContainer}>
          <Button
            variant="ghost"
            endIcon={<Icon svg={ArrowRight} color="accent" size={5} />}
            onClick={navigateToRewardsTransactions}
            attributes={{ 'data-testid': 'see-all-button' }}
          >
            <Text size="100">{labels.lblseeAll}</Text>
          </Button>
        </View>
      </Hidden>
    </View>
  );
};
