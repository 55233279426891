/**
 * Copyright 2025 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ContentStackIframe } from '../../interface';
import CMSIframeStyles from './CMSIframe.module.scss';

type CMSIframeProps = {
  content: ContentStackIframe;
};

const DEFAULT_IFRAME_HEIGHT = 1500;

export const CMSIframe = ({ content }: CMSIframeProps) => {
  const iframeSrc = content.source_url?.href ?? '';
  const tabletHeight = content.iframe_height?.tablet ?? DEFAULT_IFRAME_HEIGHT;
  const mobileHeight = content.iframe_height?.mobile ?? DEFAULT_IFRAME_HEIGHT;
  const desktopHeight = content.iframe_height?.desktop ?? DEFAULT_IFRAME_HEIGHT;

  return (
    <iframe
      src={iframeSrc}
      title={content.title}
      className={CMSIframeStyles.iframe}
      style={
        {
          '--mobile-height': `${mobileHeight}px`,
          '--tablet-height': `${tabletHeight}px`,
          '--desktop-height': `${desktopHeight}px`,
        } as React.CSSProperties
      }
    />
  );
};
