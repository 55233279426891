/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import VinDecoder from '@/features/landingPage/components/VinDecoder';
import { View, Text } from '@az/starc-ui';
import CMSVinDecoderStyles from './CMSVinDecoder.module.scss';
import { useLabels } from '@/hooks/useLabels';

const labelMap = {
  vinDecoder: 'label_landingPage_vinDecoder',
};

export const CMSVinDecoder = () => {
  const labels = useLabels(labelMap);
  return (
    <View direction="column" className={CMSVinDecoderStyles.container}>
      <Text>{labels.vinDecoder}</Text>
      <VinDecoder />
    </View>
  );
};
